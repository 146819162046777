import React, { useState, useRef } from "react";
import Swal from "sweetalert2";
import "./uploadfilepdf.css";

const UploadFilePDF = () => {
  const [file, setFile] = useState(null);
  const [indexName, setIndexName] = useState(""); // Estado para indexName
  const [pineconeApiKey, setPineconeApiKey] = useState(""); // Estado para pineconeApiKey
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const CORS = process.env.REACT_APP_CORS;
  const URL_BASE_IA_VECTORIAL = process.env.REACT_APP_URL_BASE_IA_VECTORIAL;
  const fileInputRef = useRef(null); // Referencia al input de archivo

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Debe seleccionar un archivo en formato PDF",
      });
      return;
    }

    if (!indexName || !pineconeApiKey) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Debe ingresar un nombre de Indexe y una API Key.",
      });
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    console.log("FormData:", formData.get("file"));
    console.log("Index Name:", indexName);
    console.log("Pinecone API Key:", pineconeApiKey);

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
        URL_BASE_IA_VECTORIAL + "ia-fenalco/upload-pdf",
      true
    );

    // Añadir los headers
    xhr.setRequestHeader("indexName", indexName);
    xhr.setRequestHeader("pineconeApiKey", pineconeApiKey);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      setUploading(false);
      if (xhr.status === 200) {
        console.log("Response from API:", xhr.responseText);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "File uploaded successfully!",
        });
        setFile(null); // Limpiar el estado del archivo después de la subida exitosa
        fileInputRef.current.value = ""; // Limpiar el input de archivo
        setProgress(0);
      } else if (xhr.status === 401) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "La API Key es incorrecta o no está autorizada",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al subir el archivo, por favor valide el nombre del Indexe y la API Key, si el problema persiste contacte al administrador",
        });
      }
    };

    xhr.onerror = () => {
      setUploading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while uploading the file",
      });
    };

    xhr.send(formData);
  };

  // Función para redirigir a la URL
  const handleTestModel = async () => {
    if (!indexName) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Debe ingresar un nombre de Indexe para probar el modelo.",
      });
      return;
    }

    const url = `https://copilotasertis.fenalcovalle.com/${indexName}`;

    try {
      const response = await fetch(url, { method: "HEAD" });

      // Si la respuesta es 200, significa que la ruta existe
      if (response.ok) {
        window.open(url, "_blank"); // Abre la nueva URL en una pestaña si existe
      } else {
        // Si la respuesta no es 200, mostrar mensaje de error
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: `La ruta ${url} no existe. Verifique el nombre del Indexe.`,
        });
      }
    } catch (error) {
      // Manejar cualquier error de red o de fetch
      Swal.fire({
        icon: "error",
        title: "Debes ingresar un nombre de modelo válido!",
        text: "No se pudo verificar la existencia de la ruta.",
      });
    }
  };

  return (
    <div className="upload-container">
      <h1>Entrenar modelos OpenAI</h1>
      <p>
        Selecciona el PDF con el cual desea entrenar el modelo, luego presiona
        el botón "Upload PDF".
      </p>

      {/* Input para indexName */}
      <div className="input-with-button">
        <input
          type="text"
          placeholder="Ingrese el nombre del Indexe"
          value={indexName}
          onChange={(e) => setIndexName(e.target.value)}
          className="text-input-index"
        />
      </div>

      {/* Input para pineconeApiKey */}
      <input
        type="text"
        placeholder="Ingrese la API Key 🔐"
        value={pineconeApiKey}
        onChange={(e) => setPineconeApiKey(e.target.value)}
        className="text-input-key"
      />

      <input
        type="file"
        accept=".pdf"
        onChange={handleFileChange}
        className="file-input"
        ref={fileInputRef} // Añadir la referencia al input de archivo
      />

      <button
        onClick={handleUpload}
        className="upload-button"
        disabled={uploading}
      >
        {uploading ? "Uploading..." : "Upload PDF 📤"}
      </button>
      <br />
      

      {/* Condicionalmente renderizar el botón si indexName no está vacío */}
      {indexName && (
        <button onClick={handleTestModel} className="test-button">
          Probar modelo
        </button>
      )}

      {uploading && (
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}

      {file && !uploading && (
        <div className="file-info">
          <p>
            <strong>Elegir archivo:</strong> {file.name}
          </p>
        </div>
      )}
    </div>
  );
};

export default UploadFilePDF;